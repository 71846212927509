import React from "react";
import { useAppSelector } from "../../store/hooks";
import { selectAdList } from "../../pages/home/homeSlice";
import { getAdContentHtml } from "../../utils/tools";

const AdComponent = ({ code }) => {
  return <div dangerouslySetInnerHTML={{ __html: code }}></div>;
};

export function HomeTopBanner() {}

export function HomeAnchor() {
  const adList = useAppSelector(selectAdList);

  let targetAdInfo = adList.find((item) => item.adunitid === "Anchorbanner");

  if (targetAdInfo) {
    const codeHtml = getAdContentHtml("anchor", targetAdInfo);
    return <AdComponent code={codeHtml} />;
  }
  return null;
}
