import React from "react";
import "./index.css";
import NavBtn from "../NavBtn";

export default function Policy() {
  return (
    <div className="w-full px-[1rem] py-[1rem]">
      <NavBtn />
      <h1>Privacy Policy</h1>
      <h2>Policies for Handling Personal Information</h2>
      <p>
        tapforgame.com handles users' personal information according to the
        following policies. The company respects the privacy rights of its users
        and protects the personal information collected, stored, and used in
        connection with our service (our "Service"). To further this commitment,
        we have adopted this Privacy Policy ("Privacy Policy") to guide how we
        collect, store, and use the information you provide us.
      </p>
      <p>
        By accessing our Service, including registering for a tapforgame.com
        account, downloading and using software made available by
        tapforgame.com, or by clicking the "I accept" button (or its
        equivalent), you are accepting the practices described in this Privacy
        Policy. If you do not agree to this Privacy Policy, please do not
        register for an account with tapforgame.com, download software made
        available by tapforgame.com, or otherwise access our Service.
      </p>
      <p>
        YOU AFFIRM THAT YOU ARE OVER THE AGE OF 18 (OR, IF GREATER THAN 18, THE
        AGE OF MAJORITY IN YOUR JURISDICTION) AND ARE OF LEGAL AGE IN YOUR
        JURISDICTION OR RESIDENCE, OR POSSESS LEGAL PARENTAL OR GUARDIAN CONSENT
        TO ENTER INTO A BINDING CONTRACT. IN ANY CASE, YOU AFFIRM THAT YOU ARE
        OVER THE AGE OF 13. IF YOU ARE 13 OR UNDER, YOU ARE PROHIBITED FROM
        USING OUR SERVICE.
      </p>

      <h2>1. Scope of Privacy Policy</h2>
      <p>
        This Privacy Policy describes tapforgame.com's collection and use of
        personal information submitted and collected through our Service.
        Personal information includes any individually identifiable information
        about you, such as your name, address, telephone number, or e-mail
        address, and information concerning you collected and/or maintained in
        personally identifiable form. Please be aware that advertisers or
        websites with links to and from our Service may collect personal
        information about you. This Privacy Policy does not apply to, and cannot
        control the activities of, those other advertisers or websites.
      </p>

      <h2>2. Information We Collect</h2>
      <p>
        We offer a diverse mix of games created by independent developers. You
        can access our service without registering or logging in. Certain games
        on our website are controlled by third parties, including third parties
        located outside the EU/EEA, which shall be borne by its own privacy
        policy respectively. For those games hosted by us, we only collect and
        process your personal data in the minimum scope necessary for realizing
        the following purposes based on the appropriate legal basis.
      </p>
      <ul>
        <li>
          2.1 In order to provide some location-based services such as forming a
          team with people nearby, we may collect your IP address, not your real
          IP address, but the IP address of the telecom operator.
        </li>
        <li>
          2.2 In order to improve our Services and correct bugs or errors that
          occur when you are playing the game, we may collect relevant
          information including but not limited to your behavioral data in the
          game, device information, and network status.
        </li>
        <li>
          2.3 In order to comply with applicable law (such as anti-money
          laundering regulations), we may collect your device and network
          information. The processing activity for this purpose is necessary for
          compliance with the legal obligation we are subject to.
        </li>
      </ul>

      <h2>3. Tracking Technologies & Cookies</h2>
      <p>
        tapforgame.com and our partners, such as marketing partners and
        analytics providers, may use tracking technologies such as cookies,
        beacons, tags, and scripts. These technologies are used in analyzing
        trends, administering our websites, tracking users’ movements around the
        websites, and to gather demographic information about our user base as a
        whole. We use cookies for various purposes including our shopping cart,
        to remember users’ settings, and for authentication. Users can control
        the use of cookies by adjusting individual browser settings. However, if
        you reject cookies, you may still use our websites, but your ability to
        use some features or areas of our websites may be limited. We may also
        use clear gifs in our HTML-based emails to let us know which emails have
        been opened by recipients. This allows us to gauge the effectiveness of
        certain communications and the effectiveness of our marketing campaigns.
        For this reason, these types of clear gifs are tied to personally
        identifiable information.
      </p>
      <p>
        We may also use tools or third-party analytical software to
        automatically collect and use certain non-personal information that does
        not directly enable tapforgame.com to identify you.
      </p>

      <h2>
        4. How We Entrust a Third-Party to Process, Share, Transfer, and
        Disclose Your Personal Data
      </h2>
      <p>
        We may share your personal data with the following categories of third
        parties in order to provide our Services to you:
      </p>
      <ul>
        <li>
          4.1 Affiliates: We are a global company and have affiliates all over
          the world. In order to provide our Services, we will share your
          personal data with our affiliates based on the respective legal basis
          and data processing agreements signed between us.
        </li>
        <li>
          4.2 Advertising: This type of service allows User Data to be utilized
          for advertising communication purposes displayed in the form of
          banners and other advertisements on this Website, possibly based on
          User interests. This does not mean that all Personal Data are used for
          this purpose. Information and conditions of use are shown below. Some
          of the services listed below may use Cookies to identify Users or they
          may use the behavioral retargeting technique, i.e., displaying ads
          tailored to the User's interests and behavior, including those
          detected outside this Website. For more information, please check the
          privacy policies of the relevant services.
        </li>
      </ul>

      <h2>5. Safeguards</h2>
      <p>
        tapforgame.com maintains appropriate safeguards that ensure the
        security, integrity, and privacy of the personal information we collect
        and store about our account holders. Personal information collected by
        tapforgame.com is stored in secure operating environments that are not
        available to the public and that are only accessible by authorized
        employees. We also have security measures in place to protect the loss,
        misuse, and alteration of the information under our control (i.e.,
        maintain data quality). Before we allow users to access their personal
        information, for example, we verify their identity by requesting that
        they submit information such as their username and password. We also
        take reasonable steps to ensure that third parties to whom we transfer
        personal information provide sufficient protection of such data.
      </p>
      <p>
        Your personal information may be transferred to, and stored at, a
        destination outside the country of your residence. It may also be
        processed by personnel of our affiliates and third-party service
        providers who may operate outside the country of your residence. In such
        cases, we will take reasonable steps to require such third party in
        possession of your personal information to take commercially reasonable
        security measures to protect the information. However, unfortunately, no
        data transmission over the internet or any wireless network can be
        guaranteed to be secure. As a result, while we strive to protect your
        personal information, you acknowledge that: (a) the security, integrity,
        and privacy of any and all information and data exchanged between you
        and us through our Service cannot be guaranteed; and (b) any such
        information and data may be viewed or tampered with in transit by a
        third party.
      </p>

      <h2>6. Links to Third Party Sites or Services</h2>
      <p>
        You should be aware that our Service may from time to time contain links
        to third-party sites, products, or services. tapforgame.com is not
        responsible for the privacy practices or the content of such sites or
        services. If you are concerned about the privacy policy of a certain
        third party, you should read the privacy policy of the site or service
        to which you link before you submit any personal information.
      </p>

      <h2>7. Common ID Cookie</h2>
      <p>
        This site uses cookies and similar tracking technologies such as the
        Common ID cookie to provide its services. Cookies are important devices
        for measuring advertising effectiveness and ensuring a robust online
        advertising industry. The Common ID cookie stores a unique user id in
        the first-party domain and is accessible to our ad partners. This simple
        ID can be utilized to improve user matching, especially for delivering
        ads to iOS and MacOS browsers. Users can opt out of the Common ID
        tracking cookie by clicking{" "}
        <a href="https://www.networkadvertising.org/choices/">here</a>.
      </p>

      <h2>8. Access to Personal Information</h2>
      <p>
        tapforgame.com makes good faith efforts to enable you to review, update,
        or correct your personal information in our possession. In order to do
        so, please log into your account or contact our Privacy Policy
        Administrator at solidclix.cathrine@gmail.com. If you contact us, we
        will need sufficient information from you to establish your identity and
        to verify your access request, and also to assist us in handling your
        request. Please note that even if personal information is changed or
        deleted according to your request, tapforgame.com may still retain some
        of your personal information to resolve disputes, enforce tapforgame.com
        user agreement(s), and comply with technical and legal requirements and
        constraints related to the security, integrity, and operation of our
        Service.
      </p>

      <h2>9. Deactivated or Terminated Accounts</h2>
      <p>
        A request to delete and/or terminate your account will not result in the
        removal of information collected and already put in aggregate form or
        information that cannot, in our sole discretion, be removed without
        undue burden to us. We are not able nor are we obligated to remove any
        of your information from a third-party provider or platform. Moreover,
        please be aware that even after you delete your account or your account
        is terminated, your information may not be deleted in its entirety from
        our servers and servers of the third party with whom such information
        has been shared.
      </p>

      <h2>10. Inquiries</h2>
      <p>
        If you have any questions, please feel free to email us at
        solidclix.cathrine@gmail.com.
      </p>
    </div>
  );
}
