import React, { useMemo } from "react";
import GamePlayCard from "../../components/GamePlayCard";
import HotGame from "./HotGame";
import RecentGame from "./RecentGame";
import GameAbout from "./GameAbout";
import Footer from "../footer";
import NavBtn from "../../components/NavBtn";
import { useAppSelector } from "../../store/hooks";
import {
  selectGameList,
  selectHotGames,
  selectRecommendGames,
} from "../home/homeSlice";
import { useParams } from "react-router-dom";
import MoreBtn from "../../components/MoreBtn";
import { DetailBottomBanner, DetailMidBanner } from "../../components/Ad";

export default function Detail() {
  const { id } = useParams();
  const hotGames = useAppSelector(selectHotGames);
  const recentGames = useAppSelector(selectRecommendGames);
  const allGames = useAppSelector(selectGameList);

  const gameItem = useMemo(() => {
    const target = allGames.find((item) => item.id === +id);
    return target;
  }, [allGames, id]);

  return (
    <div className="w-full max-w-[450px] min-w-[320px] mx-auto min-h-screen px-[0.75rem] py-[0.5rem]">
      <NavBtn toPath={"/home"} />
      <GamePlayCard {...gameItem} />
      <DetailMidBanner />
      <div className="w-full my-[1rem]">
        <HotGame list={hotGames} />
      </div>
      <div className="w-full my-[1rem]">
        <RecentGame list={recentGames} />
      </div>
      <div className="w-full my-[1rem]">
        <GameAbout name={gameItem.name} desc={gameItem.description} />
      </div>
      <MoreBtn />
      <Footer />
      <DetailBottomBanner />
    </div>
  );
}
