import React from "react";
import { Link } from "react-router-dom";

export default function Footer() {
  return (
    <div>
      <div className="w-full flex items-center justify-center my-[1rem]">
        <div className="w-max">
          <Link to={"/terms"}>Users Agreement </Link>
        </div>
        <div className="w-max  px-[0.5rem]">
          <Link to={"/policy"}>| Privacy Policy</Link>
        </div>
        <div className="w-max">
          <Link to={"/about"}>| About</Link>
        </div>
      </div>
      <p className="w-full text-center text-base font-normal">
        Copyright © 2024 TapForGame. All rights Reserved.
      </p>
    </div>
  );
}
